const React = require('react');
const T = require('prop-types');
const { Pie, Bar, Line } = require('react-chartjs-2');
const { default: Grid } = require('@mui/material/Grid');
const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemIcon } = require('@mui/material/ListItemIcon');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: Home } = require('@mui/icons-material/Home');
const { default: CalendarToday } = require('@mui/icons-material/CalendarToday');
const { default: Settings } = require('@mui/icons-material/Settings');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { Link } = require('react-router-dom');
const { default: Paper } = require('@mui/material/Paper');
const { default: Typography } = require('@mui/material/Typography');
const { default: styled } = require('styled-components');
const { MAX_CONTENT_WIDTH } = require('utils/constants');
const { default: ReactWordcloud } = require('react-wordcloud');

const StyledPaper = styled(Paper)`
    && {
        padding: 1.5em;
        margin: 1em;
        min-height: 120px;
        aspect-ratio: 2/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

const StyledDashboardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 100vw;
    background-color: white;
    left: calc(((100vw - ${MAX_CONTENT_WIDTH}px) / 2) * -1);
    top: 0;

    @media (max-width: 600px) {
        left: 0;
        width: 100%;
    }
`;

module.exports = class AdminDashboard extends React.PureComponent {
    static propTypes = {
        userStats: T.object,
        messagesStats: T.object,
        fetchUserStats: T.func.isRequired,
        fetchMessagesStats: T.func.isRequired
    }

    state = {
        modalVisible: false,
        currentChartData: null,
        currentChartTitle: null,
        expandOthers: false,
        loading: true,
        groupsDialogOpen: false,
        selectedGroup: null,
        showPassionInterests: false,
        showPassionCategories: false
    }

    chartOptions = {
        padding: {
            top: 20
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 10,
                fontSize: 12,
                fontStyle: 'normal',
                padding: 20
            },
            maxWidth: 20,
            marginBottom: '50px'
        },
        plugins: {
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = ((value / total) * 100).toFixed(1);
                        return `${context.label}: ${value} (${percentage}%)`;
                    }
                }
            }
        }
    }

    componentDidMount = () => {
        this.props.fetchUserStats().then((response) => {
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            console.error('Error fetching user stats', error);
        });

        this.props.fetchMessagesStats().then((response) => {
            if (response?.byGroup?.length > 0) {
                this.setState({
                    selectedGroup: response.byGroup[0],
                    loading: false
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false });
            console.error('Error fetching messages stats', error);
        });
    }

    createPieData = (data, labelKey = 'name', valueKey = 'count') => {
        const sortedData = [...data].sort((a, b) => b[valueKey] - a[valueKey]);

        const total = sortedData.reduce((sum, item) => sum + item[valueKey], 0);
        const threshold = 0.05;

        const mainData = [];
        const otherData = [];

        sortedData.forEach((item) => {
            let label = item[labelKey];
            if (item.role?.name) {
                label = item.role.name;
            }

            if (item.type) {
                label = item.type;
            }

            if (item.year) {
                label = `Class of ${item.year}`;
            }

            if ('isVeteran' in item) {
                label = item.isVeteran ? 'Veteran' : 'Non-Veteran';
            }

            if ('isParent' in item) {
                label = item.isParent ? 'Parent' : 'Non-Parent';
            }

            if ('isTransfer' in item) {
                label = item.isTransfer ? 'Transfer' : 'Non-Transfer';
            }

            if ('isOnline' in item) {
                label = item.isOnline ? 'Online' : 'In-Person';
            }

            if ('isFullTime' in item) {
                label = item.isFullTime ? 'Full Time' : 'Part Time';
            }

            if ('isRemote' in item) {
                label = item.isRemote ? 'Remote' : 'On-Site';
            }

            if (item[valueKey] / total >= threshold) {
                mainData.push({ ...item, displayLabel: label });
            }
            else {
                otherData.push({ ...item, displayLabel: label });
            }
        });

        const finalData = this.state.expandOthers ?
            [...mainData, ...otherData] :
            [...mainData, otherData.length ? {
                [valueKey]: otherData.reduce((sum, item) => sum + item[valueKey], 0),
                displayLabel: 'Other'
            } : null].filter(Boolean);

        return {
            labels: finalData.map((item) => item.displayLabel),
            datasets: [{
                data: finalData.map((item) => item[valueKey]),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)'
                ].slice(0, finalData.length),
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ].slice(0, finalData.length),
                borderWidth: 1
            }]
        };
    }

    toggleExpandOthers = () => {
        this.setState((prevState) => ({
            expandOthers: !prevState.expandOthers
        }));
    }

    showModal = (title, data) => {
        this.setState({
            modalVisible: true,
            currentChartData: data,
            currentChartTitle: title
        });
    }

    closeModal = () => {
        this.setState({
            modalVisible: false,
            currentChartData: null,
            currentChartTitle: null
        });
    }

    escListener = (evt) => {
        if (evt.key === 'Escape') {
            this.closeModal();
        }
    }

    getDialogRef = (node) => {
        if (node && !this.initFocus) {
            this.initFocus = true;
            node.focus();
        }
    }

    getButtonConfig = (key, data) => {
        const titleMap = {
            roles: 'User Roles',
            incomingClass: 'Incoming Class',
            graduatingClass: 'Graduating Class',
            fullTime: 'Student Status',
            gender: 'Genders',
            veteran: 'Veterans',
            transfer: 'Transfer Students',
            parent: 'Parent Status',
            online: 'Learning Mode',
            remote: 'Location Status'
        };

        if (key === 'fullTime') {
            return {
                title: titleMap[key],
                data: [
                    { name: 'Full Time', count: data.find((x) => x.isFullTime)?.count || 0 },
                    { name: 'Part Time', count: data.find((x) => !x.isFullTime)?.count || 0 }
                ]
            };
        }

        return {
            title: titleMap[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
            data
        };
    }

    renderUserInfoButtons = () => {
        const { userStats } = this.props;
        const excludedFields = ['verificationStatus', 'activityStatus', 'newUsers', 'majors', 'total', 'dailyActivity', 'userGrowth', 'categories', 'interests', 'connectionsMade', 'passionInterests', 'passionCategories'];

        return Object.entries(userStats || {}).map(([key, value]) => {
            if (excludedFields.includes(key) || !value) {
                return null;
            }

            const { title, data } = this.getButtonConfig(key, value);

            return (
                <Button
                    key={key}
                    onClick={() => this.showModal(title, data)}
                    sx={{ justifyContent: 'flex-start' }}
                >
                    {title}
                </Button>
            );
        });
    }

    renderUserInfoModal = () => {
        const { modalVisible, currentChartData, currentChartTitle } = this.state;

        if (!modalVisible || !currentChartData) {
            this.initFocus = false;
            return null;
        }

        const pieData = this.createPieData(currentChartData);

        return (
            <Dialog
                open={modalVisible}
                onClose={this.closeModal}
                maxWidth="md"
                fullWidth
                aria-labelledby="chart-dialog-title"
            >
                <div
                    tabIndex={0}
                    ref={this.getDialogRef}
                    data-focus-outline="radius:15"
                    onKeyDown={this.escListener}
                >
                    <DialogTitle id="chart-dialog-title">{currentChartTitle}</DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Button
                                    size="small"
                                    onClick={this.toggleExpandOthers}
                                >
                                    {this.state.expandOthers ? 'Group Others' : 'Show Others'}
                                </Button>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Pie
                                    data={pieData}
                                    options={this.chartOptions}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeModal}
                            color="primary"
                            data-focus-outline="radius:40"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }

    renderPieChart = (title, data, labelKey, valueKey) => {
        return (
            <Grid item xs={12} md={6} lg={4}>
                <StyledPaper>
                    {this.renderUserInfoModal()}
                </StyledPaper>
            </Grid>
        );
    }

    handleGroupsDialogOpen = () => {
        if (!this.state.selectedGroup && this.props.messagesStats?.byGroup?.length > 0) {
            this.setState({
                groupsDialogOpen: true,
                selectedGroup: this.props.messagesStats.byGroup[0]
            });
        }
        else {
            this.setState({ groupsDialogOpen: true });
        }
    }

    handleGroupsDialogClose = () => {
        this.setState({ groupsDialogOpen: false, selectedGroup: null });
    }

    handleGroupSelect = (group) => {
        this.setState({ selectedGroup: group });
    }

    getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    renderGroupsDialog = () => {
        const { groupsDialogOpen, selectedGroup } = this.state;
        const { messagesStats } = this.props;
        const groupsData = messagesStats?.byGroup || [];

        return (
            <Dialog
                open={groupsDialogOpen}
                onClose={this.handleGroupsDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Groups Activity</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <div style={{ display: 'flex', height: '400px' }}>
                        {/* Groups List */}
                        <div style={{
                            width: '200px',
                            borderRight: '1px solid #e0e0e0',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}>
                            <List sx={{
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    display: 'block'
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555'
                                }
                            }}>
                                {groupsData.map((group, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={() => this.handleGroupSelect(group)}
                                        sx={{
                                            backgroundColor: selectedGroup?.conversationId === group.conversationId ? '#f5f5f5' : 'white',
                                            borderBottom: '1px solid #e0e0e0',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5'
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={group.className}
                                            secondary={`${group.count} messages`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        {/* Group Details */}
                        <div style={{
                            flex: 1,
                            padding: '20px',
                            overflow: 'hidden'
                        }}>
                            {selectedGroup ? (
                                <>
                                    <Typography variant="h6">{selectedGroup.className}</Typography>
                                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                        <Typography variant="body1">
                                            Total Messages: {selectedGroup.count}
                                        </Typography>
                                        <Typography variant="body1" style={{ marginTop: '5px' }}>
                                            Active Participants: {selectedGroup.participantCount}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" style={{ marginTop: '5px' }}>
                                            Average Messages per Participant: {(selectedGroup.count / selectedGroup.participantCount).toFixed(1)}
                                        </Typography>
                                    </div>
                                    {selectedGroup.topWords && selectedGroup.topWords.length > 0 && (
                                        <div style={{ height: '300px' }}>
                                            <ReactWordcloud
                                                words={selectedGroup.topWords.map((word) => ({
                                                    text: word.word,
                                                    value: word.count,
                                                    color: this.getRandomColor()
                                                }))}
                                                options={{
                                                    padding: 5,
                                                    rotations: 0,
                                                    deterministic: true,
                                                    enableTooltip: true,
                                                    fontSizes: [30, 70]
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                    Select a group to view details
                                </Typography>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleGroupsDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    getSvgImageData = (svg) => {
        if (!svg) {
            return null;
        }

        const buff = Buffer.from(svg);
        const base64data = buff.toString('base64');
        return `data:image/svg+xml;base64,${base64data}`;
    }

    toggleInterestsView = () => {
        this.setState((prevState) => ({
            showPassionInterests: !prevState.showPassionInterests
        }));
    }

    toggleCategoriesView = () => {
        this.setState((prevState) => ({
            showPassionCategories: !prevState.showPassionCategories
        }));
    }

    render = () => {
        const { userStats = {}, messagesStats = {} } = this.props;
        console.log(userStats);
        const { loading } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        const groupsData = messagesStats?.byGroup || [];

        const topMetrics = [
            { label: 'Messages Sent This Week', value: messagesStats?.total || 0 },
            { label: 'New Users This Week', value: userStats?.newUsers || 0 },
            { label: 'Connections Made This Week', value: userStats?.connectionsMade || 0 }
        ];

        const averageUserMetrics = [
            {
                label: 'Messages',
                value: userStats?.averageUserStats?.averages?.messagesPerUser?.toFixed(1) || '0'
            },
            {
                label: 'Groups',
                value: userStats?.averageUserStats?.averages?.groupsPerUser?.toFixed(1) || '0'
            },
            {
                label: 'Connections',
                value: userStats?.averageUserStats?.averages?.connectionsPerUser.toFixed(1) || '0'
            }
        ];

        const userGrowthData = {
            labels: (userStats?.userGrowth || [])
                .map((day) => new Date(day.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })),
            datasets: [{
                label: 'Total Users',
                data: (userStats?.userGrowth || [])
                    .map((day) => day.count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                tension: 0.3,
                fill: true
            }]
        };

        const barOptions = {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                yAxes: [{
                    id: 'y',
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 20,
                        callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                        }
                    }
                }]
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'Daily Active Users (Past Week)'
                }
            }
        };

        const lineOptions = {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                yAxes: [{
                    id: 'y',
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 100,
                        callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                        }
                    }
                }]
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'Total User Growth'
                }
            }
        };

        return (
            <StyledDashboardWrapper>
                <div style={{ width: '100%', display: 'flex' }}>
                    {/* Left Menu */}
                    <div style={{ width: '250px', marginRight: '2em', marginLeft: '1em' }}>
                        <Typography variant="h4" style={{ marginTop: '2em' }} sx={{ pl: 2 }}>
                            Admin Menu
                        </Typography>
                        <List>
                            <ListItem
                                component={Link}
                                to="/app/admin/preapprove-users"
                                sx={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText primary="Preapprove Users" />
                            </ListItem>
                            <ListItem
                                component={Link}
                                to="/app/admin/batch-notify-users"
                                sx={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <CalendarToday />
                                </ListItemIcon>
                                <ListItemText primary="Batch Notify Users" />
                            </ListItem>
                            <ListItem
                                component={Link}
                                to="/app/surveys/management"
                                sx={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary="Surveys Management" />
                            </ListItem>
                        </List>

                        {/* Groups Data Section */}
                        <Typography variant="h4" style={{ marginTop: '2em' }} sx={{ pl: 2 }}>
                            Groups Activity
                        </Typography>
                        <List>
                            {groupsData.slice(0, 5).map((group, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => {
                                        this.setState({
                                            groupsDialogOpen: true,
                                            selectedGroup: group
                                        });
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <ListItemText
                                        primary={group.className}
                                        secondary={`${group.count} messages this week`}
                                    />
                                </ListItem>
                            ))}
                            {groupsData.length > 5 && (
                                <ListItem
                                    onClick={this.handleGroupsDialogOpen}
                                    sx={{
                                        color: 'primary.main',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <ListItemText primary={`View All (${groupsData.length})`} />
                                </ListItem>
                            )}
                        </List>
                        {this.renderGroupsDialog()}
                    </div>

                    {/* Main Content */}
                    <div style={{ borderLeft: '1px solid #e0e0e0', paddingLeft: '2em', backgroundColor: '#fafafa' }}>
                        <div style={{ display: 'flex', gap: '1em' }}>
                            <div style={{ flex: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" align="left" style={{ marginLeft: '8px', marginTop: '2em' }}>
                                            Top metrics this week
                                        </Typography>
                                    </Grid>
                                    {topMetrics.map((metric, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ marginLeft: '1.2em' }}>
                                            <StyledPaper>
                                                <Typography variant="h6" style={{ marginBottom: '0.5em' }}>{metric.label}</Typography>
                                                <Typography variant="h4">{metric.value}</Typography>
                                            </StyledPaper>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" align="left" style={{ marginLeft: '8px', marginTop: '2em' }}>
                                            The average user
                                        </Typography>
                                    </Grid>
                                    {averageUserMetrics.map((metric, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ marginLeft: '1.2em' }}>
                                            <StyledPaper>
                                                <Typography variant="h6" style={{ marginBottom: '0.5em' }}>{metric.label}</Typography>
                                                <Typography variant="h4">{metric.value}</Typography>
                                            </StyledPaper>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container style={{ marginTop: '2em' }}>
                                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                        <Typography variant="h4" align="left" style={{ marginLeft: '8px' }}>
                                            User Growth
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <StyledPaper style={{ height: '310px' }}>
                                            <Line
                                                data={userGrowthData}
                                                options={lineOptions}
                                            />
                                        </StyledPaper>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} style={{ marginTop: '1em' }}>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <Typography variant="h4" align="left">
                                                Top 10 {this.state.showPassionInterests ? 'Passions' : 'Interests'}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={this.toggleInterestsView}
                                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                            >
                                                Show {this.state.showPassionInterests ? 'Interests' : 'Passions'}
                                            </Button>
                                        </div>
                                        <List>
                                            {(this.state.showPassionInterests ? userStats?.passionInterests : userStats?.interests || []).map((interest, index) => (
                                                <ListItem key={index}>
                                                    <div style={{ width: '36px', display: 'flex', justifyContent: 'center' }}>
                                                        {interest.svg && (
                                                            <img
                                                                alt={`${interest.name} icon`}
                                                                src={this.getSvgImageData(interest.svg)}
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <ListItemText
                                                        primary={index + 1 + '. ' + interest.name}
                                                        secondary={`${interest.count} students`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <Typography variant="h4" align="left">
                                                Top 10 {this.state.showPassionCategories ? 'Passion Categories' : 'Categories'}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={this.toggleCategoriesView}
                                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                            >
                                                Show {this.state.showPassionCategories ? 'Categories' : 'Passion Categories'}
                                            </Button>
                                        </div>
                                        <List>
                                            {(this.state.showPassionCategories ? userStats?.passionCategories : userStats?.categories || []).map((category, index) => (
                                                <ListItem key={index}>
                                                    <div style={{ width: '36px', display: 'flex', justifyContent: 'center' }}>
                                                        {category.svg && (
                                                            <img
                                                                alt={`${category.name} icon`}
                                                                src={this.getSvgImageData(category.svg)}
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <ListItemText
                                                        primary={index + 1 + '. ' + category.name}
                                                        secondary={`${category.count} students`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h4" align="left" style={{ marginLeft: '8px' }}>
                                            Top 10 Custom Interests
                                        </Typography>
                                        <List>
                                            {(userStats?.customInterests || []).map((customInterest, index) => (
                                                <ListItem key={index}>
                                                    {customInterest.svg && (
                                                        <img
                                                            alt={`${customInterest.name} icon`}
                                                            src={this.getSvgImageData(customInterest.svg)}
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                                marginRight: '12px'
                                                            }}
                                                        />
                                                    )}
                                                    <ListItemText
                                                        primary={index + 1 + '. ' + customInterest.name}
                                                        secondary={`${customInterest.count} students`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>

                            {/* Right side (User Info) */}
                            <div style={{ display: 'flex', flexDirection: 'column', width: '170px', marginRight: '2em' }}>
                                <Typography variant="h4" style={{ marginBottom: '1em', marginTop: '2em' }}>
                                    User Info
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                    {this.renderUserInfoButtons()}
                                </div>
                                {this.renderUserInfoModal()}
                            </div>
                        </div>
                    </div>
                </div>
            </StyledDashboardWrapper>
        );
    }
};

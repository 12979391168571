const { DASHBOARD: Types } = require('../action-types');
const { combineReducers } = require('redux');

const userStats = (state = null, action) => {
    switch (action.type) {
        case Types.GET_USER_STATS.SUCCESS:
            return action.payload?.result || null;
        default:
            return state;
    }
};

const messagesStats = (state = null, action) => {
    switch (action.type) {
        case Types.GET_MESSAGES_STATS.SUCCESS:
            return action.payload?.result || null;
        default:
            return state;
    }
};

const reducer = combineReducers({
    userStats,
    messagesStats
});

const selectors = {
    getUserStats: (state) => state.userStats,
    getMessagesStats: (state) => state.messagesStats
};

module.exports = Object.assign(reducer, { selectors });

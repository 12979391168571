const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { DASHBOARD: Types } = require('../action-types');

module.exports = (context) => {
    const api = context.api.nearpeer;

    return {
        getUserStats: makeActionCreator(Types.GET_USER_STATS, {
            startDate: T.instanceOf(Date),
            endDate: T.instanceOf(Date)
        }, {
            async: api.dashboard.getUserStats
        }),
        getMessagesStats: makeActionCreator(Types.GET_MESSAGES_STATS, {
        }, {
            async: api.dashboard.getMessagesStats
        })
    };
};

const { makeActionTypes } = require('../utils/redux-helpers');

module.exports = makeActionTypes({
    ALERTS:             require('./alerts'            ),
    CONNECTIVITY:       require('./connectivity'      ),
    SIGNUP:             require('./signup'            ),
    AUTH:               require('./auth'              ),
    DATA_FETCHING:      require('./data-fetching'     ),
    PROFILE_MANAGEMENT: require('./profile-management'),
    COMMUNICATION:      require('./communication'     ),
    APP_FEEDBACK:       require('./app-feedback'      ),
    NOTIFICATIONS:      require('./notifications'     ),
    CLASSES:            require('./classes'           ),
    SURVEYS:            require('./surveys'           ),
    APP:                require('./app'               ),
    CHATS:              require('./chats'             ),
    ROUTER:             require('./router'            ),
    DASHBOARD:          require('./dashboard'         )
});

const { connect } = require('react-redux');
const { context } = require('app-context');
const AdminDashboard = require('../components/AdminDashboard');

const selectors = context.selectors.all;
const actions = context.actions;
console.log('selectors', selectors);
module.exports = connect(
    (state) => ({
        userStats: selectors.getUserStats(state),
        messagesStats: selectors.getMessagesStats(state)
    }), {
        fetchUserStats: actions.dashboard.getUserStats,
        fetchMessagesStats: actions.dashboard.getMessagesStats
    }
)(AdminDashboard);

const _ = require('lodash');
const Types = require('../action-types');
const { safeThrow } = require('../utils/safe-throw');
const { push: Push } = require('connected-react-router');

module.exports = (context) => {

    const selectors = context.selectors.all;
    const actions   = context.actions;
    const redux = context.redux.hooks;

    const MESSAGES = {
        BAD_LOGIN:                  'Oops! Invalid credentials.',
        RESET_PASSWORD:             'We sent you a password reset email!',
        BAD_FIELDS:                 'Oops! Some fields are invalid.',
        BAD_CREATE_ACCOUNT:         'Oops! Invalid email.',
        EMAIL_ALREADY_EXISTS:       'An account already exists for this email.',
        ACCOUNT_CLOSED:             'That account has been disabled. Please contact "helpdesk@nearpeer.net" for additional help.',
        PHONE_ALREADY_EXISTS:       'This phone number is already in use. Please use a different one.',
        UNVERIFIED_EMAIL:           'We sent you an email! Please verify your account.',
        INVALID_PHONE:              'Oops! That phone number looks invalid.',
        INVALID_EMAIL:              'Invalid email',
        BAD_UPDATE_PROFILE:         'Oops! Something went wrong updating your profile– there may be an invalid field.',
        BAD_COMPLETE_PROFILE:       'Oops! Something went wrong completing your profile– there may be an invalid field.',
        LSU_2021_STUDENTS:          'Access is currently limited to incoming 2021 students',
        DEFAULT:                    'Oops! Something went wrong.', // Note: this default msg will be ignored
        IMAGE_TOO_LARGE:            'Picture too large, please choose another one.',
        IMAGE_FAIL_DEFAULT:         'There was a problem updating your profile picture.',
        ALREADY_HAVE_ACCOUNT:       'Please login, you already have an account.',
        LINK_EXPIRED_EMAIL_SENT:    'This link has expired, we sent you a new email.',
        LINK_EXPIRED_SSO:           'This link has expired, please login with SSO.',
        CLICK_EMAIL_LINK_AGAIN:     'Please click your email link again!'
    };

    const MESSAGE_SELECTION_RULES = [
        [Types.SIGNUP.STEP_1.COMPLETE.FAILURE, ({ payload: { error, request: { email, schoolId } } }) => {

            const state = redux.getState();

            const school = selectors.getSchoolById(state, schoolId);

            let popupTitle = 'Unrecognized Email Address';
            let popupMsg = school.customSignupErrMsg || 'Please use your school email or the email you applied with. Click "Trouble Logging In?" if you need help.';

            if (error?.response?.status === 400) {
                if (error.response.data.message === 'This email address is already in use. Please use a different one.') {

                    const {
                        usingSSO
                    } = selectors.getSignupContext(state);

                    // Check if school is sso, if it is then push to signup-sso
                    if (usingSSO) {
                        redux.dispatch(Push('/signup-sso'));

                        return null;
                    }

                    popupTitle = 'Email Already Registered';
                    popupMsg = MESSAGES.CLICK_EMAIL_LINK_AGAIN;
                }
                else if (error.response.data.message === 'That account has been disabled. Please contact "helpdesk@nearpeer.net" for additional help.') {
                    popupTitle = 'Signup Error';
                    popupMsg = MESSAGES.ACCOUNT_CLOSED;
                }
            }

            const status = error?.response?.status;

            if (status === 403) {
                if (error.response.data.message === 'Sorry! Your Company and Email don’t match' || error.response.data.message === 'Sorry! Your College and Email don’t match') {
                    popupTitle = 'Signup Error';
                    popupMsg = error.response.data.message;
                }
            }

            if (error.response.data.message === 'Email has not yet been verified') {
                redux.dispatch(Push('/signup/verify-email', {
                    emailExist: true,
                    email
                }));

                return null;
            }
            else if (error.response.data.message === 'Must reset password') {
                popupTitle = 'Reset Password';
                popupMsg = 'We previously received a password reset request for this account. For your protection, you’ll need to set a new password to log in. Please check your email for the password reset link to create your new password. If you haven’t received this email within a few minutes, please check your spam folder.';
            }
            else if (error.response.data.message === 'Account already exists for this email.') {
                redux.dispatch(Push('/login'));

                return MESSAGES.ALREADY_HAVE_ACCOUNT;
            }

            redux.dispatch(actions.alerts.notification.push({
                displayType: 'popUp',
                popUpTitle: popupTitle,
                message: popupMsg,
                action: { label: 'OK', onExecute: () => redux.dispatch(actions.alerts.notification.dismiss()) }
            }));

            // Default, handle in popup
            return null;
        }],
        [Types.SIGNUP.STEP_1.FAILURE, MESSAGES.BAD_FIELDS],
        [Types.SIGNUP.COMPLETE.FAILURE, ({ payload: { error } }) => {

            if (error?.response?.status === 400) {
                if (error.response.data.message === 'Invalid phone') {
                    return MESSAGES.INVALID_PHONE;
                }

                if (error.response.data.message === 'This phone number is already in use. Please use a different one.') {
                    return MESSAGES.PHONE_ALREADY_EXISTS;
                }
            }

            return MESSAGES.BAD_COMPLETE_PROFILE;
        }],
        [Types.PROFILE_MANAGEMENT.UPDATE_PROFILE.FAILURE, ({ payload: { error } }) => {

            if (error?.response?.status === 400) {
                if (error.response.data.message === 'Invalid phone') {
                    return MESSAGES.INVALID_PHONE;
                }

                if (error.response.data.message === 'This phone number is already in use. Please use a different one.') {
                    return MESSAGES.PHONE_ALREADY_EXISTS;
                }
            }

            return MESSAGES.BAD_UPDATE_PROFILE;
        }],
        [Types.SIGNUP.SCHOOL_CHECK.FAILURE, ({ payload: { error } }) => {

            const status = error?.response?.status;

            if (status >= 400 && status < 500) {
                if (error.response.data.message?.includes('valid email')) {
                    return MESSAGES.INVALID_EMAIL;
                }
                else if (error.response.data.message === 'Email has not yet been verified') {
                    return MESSAGES.UNVERIFIED_EMAIL;
                }
                else if (error.response.data.message === 'Must reset password') {
                    redux.dispatch(actions.alerts.notification.push({
                        displayType: 'popUp',
                        popUpTitle: 'Password Reset',
                        message: 'We previously received a password reset request for this account. For your protection, you’ll need to set a new password to log in. Please check your email for the password reset link to create your new password. If you haven’t received this email within a few minutes, please check your spam folder.',
                        action: { label: 'OK', onExecute: () => redux.dispatch(actions.alerts.notification.dismiss()) }
                    }));

                    return null;
                }

                return MESSAGES.BAD_LOGIN;
            }

            return MESSAGES.DEFAULT;
        }],
        [Types.AUTH.LOGIN.FAILURE, ({ payload: { request, error } }) => {

            // Don't show toast for token logins
            if (request?.token) {
                return null;
            }

            if (error.response.data.message?.includes('valid email')) {
                redux.dispatch(actions.app.setAlertDialogMessage({
                    message: 'Invalid email, please check email again.'
                }));
                redux.dispatch(actions.app.openAlertDialog());
            }
            else if (error.response.data.message === 'Email has not yet been verified') {
                redux.dispatch(actions.app.setAlertDialogMessage({
                    message: 'We sent you an email! Please verify your account.'
                }));
                redux.dispatch(actions.app.openAlertDialog());
            }
            else if (error.response.data.message === 'Must reset password') {
                redux.dispatch(actions.alerts.notification.push({
                    displayType: 'popUp',
                    popUpTitle: 'Password Reset',
                    message: 'We previously received a password reset request for this account. For your protection, you’ll need to set a new password to log in. Please check your email for the password reset link to create your new password. If you haven’t received this email within a few minutes, please check your spam folder.',
                    action: { label: 'OK', onExecute: () => redux.dispatch(actions.alerts.notification.dismiss()) }
                }));

                return null;
            }
            else if (error.response.data.message === 'User or Password is invalid') {
                redux.dispatch(actions.app.setAlertDialogMessage({
                    message: 'Invalid email or password. Please ensure you\'re using the correct personal or school email.'
                }));
                redux.dispatch(actions.app.openAlertDialog());
            }
            else if (error.response.data.statusCode === 401) {
                redux.dispatch(actions.app.setAlertDialogMessage({
                    message: 'Sorry, the account has been disabled. Please contact Support to re-enable your account.'
                }));
                redux.dispatch(actions.app.openAlertDialog());
            }

            return null;
        }],
        [Types.AUTH.LOGIN_INPUT_FAILURE, MESSAGES.BAD_FIELDS],
        [Types.PROFILE_MANAGEMENT.CHANGE_USER_PASSWORD.FAILURE, ({ payload: { error } }) => {

            if (error?.response?.status === 400) {

                throw error;
            }
        }],
        [Types.AUTH.RESET_PASSWORD.FAILURE, ({ payload: { error } }) => {

            if (error?.response?.status === 400) {

                throw error;
            }
        }],
        [Types.PROFILE_MANAGEMENT.UPDATE_PROFILE_PICTURE.FAILURE, ({ payload: { error } }) => {

            if (error?.response?.status === 413) {
                return MESSAGES.IMAGE_TOO_LARGE;
            }

            return MESSAGES.IMAGE_FAIL_DEFAULT;
        }],
        [Types.DATA_FETCHING.FETCH_SCHOOL_BY_TOKEN.FAILURE, ({ payload: { error } }) => {

            redux.dispatch(Push('/login'));

            if (error.response.data.message === 'Account already exists for this email.') {
                return MESSAGES.ALREADY_HAVE_ACCOUNT;
            }
            else if (error.response.data.message === 'This link has expired. We sent a reminder email.') {
                return MESSAGES.LINK_EXPIRED_EMAIL_SENT;
            }
            else if (error.response.data.message === 'This link has expired.') {
                return MESSAGES.LINK_EXPIRED_SSO;
            }

            return MESSAGES.DEFAULT;
        }],
        [Types.SIGNUP.STEP_2.CHECK_PASSWORD.FAILURE, ({ payload: { error } }) => {

            redux.dispatch(Push('/login'));

            if (error.response.data.message === 'Account already exists for this email.') {
                return MESSAGES.ALREADY_HAVE_ACCOUNT;
            }
            else if (error.response.data.message === 'This link has expired. We sent a reminder email.') {
                return MESSAGES.LINK_EXPIRED_EMAIL_SENT;
            }
            else if (error.response.data.message === 'This link has expired.') {
                return MESSAGES.LINK_EXPIRED_SSO;
            }

            return MESSAGES.DEFAULT;
        }]
    ]
        .map((rule) => {

            if (typeof rule[1] !== 'function') {
                return [rule[0], () => rule[1]];
            }

            return rule;
        });

    const EXCEPT = {
        [Types.AUTH.LOGIN.FAILURE]: ({ payload: { error } }) => error && error.code === 'NO_TOKEN_ON_INIT',
        [Types.COMMUNICATION.MENTION.FAILURE]: () => true,
        [Types.DATA_FETCHING.FETCH_NOTIFICATIONS.FAILURE]: () => true
    };

    const getMessage = (action) => {

        const { error, payload } = action;

        // First see if the server wants us to display this error
        if (!!error && payload && payload.response) {
            const { error: { response: { data } } } = payload;
            if (!!data.options && !!data.options.frontendDisplay) {
                return data.message;
            }
        }

        const maybeFound = _.chain(MESSAGE_SELECTION_RULES)
            .find((rule) => rule['0'] === action.type)
            .get([1], () => MESSAGES.DEFAULT)
            .value()(action);

        if (maybeFound === MESSAGES.DEFAULT) {
            return null;
        }

        return maybeFound;
    };

    // Exceptions to the rule
    const isException = (action) => EXCEPT[action.type] && EXCEPT[action.type](action);

    return {
        messages: MESSAGES,
        pushNotification: ({ payload: { action } }) => {

            if (isException(action)) {

                if (action.payload?.error && action.payload.error.response && action.payload.error.response.status === 401) {

                    return actions.auth.accountIsClosedClearAll();
                }

                return;
            }

            const msg = getMessage(action);

            if (msg) {
                return actions.alerts.notification.push({ message: msg, origin: action });
            }

            if (action.payload?.error) {
                // TODO send this up to the server to log the error
                safeThrow(action.payload.error);
            }

            if (selectors.getHasErrorNotificationsToShow(redux.getState())) {
                return actions.alerts.notification.show();
            }
        },
        showNext: ({ state }) => {

            if (selectors.getHasErrorNotificationsToShow(state)) {
                return actions.alerts.notification.show();
            }
        }
    };
};
